import { Heading } from '@troon/ui';
import { Video } from '@troon/video';
import { NoHydration } from 'solid-js/web';
import { getConfigValue } from '../../../modules/config';
import type { RouteDefinition } from './_types';

export default function PrivateClubsManagementPage() {
	return (
		<>
			<NoHydration>
				<Heading as="h2">Top Reasons Private Clubs Partner With Troon</Heading>

				<section class="flex flex-col gap-2">
					<Heading as="h3" size="h5">
						It’s About the Club, Not Troon
					</Heading>
					<p>
						Decisions are always based on what’s best for the membership and ownership group – not what’s best for
						Troon, any individual member, or any special interest group at the club.
					</p>
				</section>

				<section class="flex flex-col gap-2">
					<Heading as="h3" size="h5">
						Sustainable Future
					</Heading>
					<p>
						The trends over the past 10 years are evidence that more and more owners are not happy with the self-managed
						status quo and are looking to the professional management-operating model to secure a sustainable future for
						the club.
					</p>
				</section>

				<section class="flex flex-col gap-2">
					<Heading as="h3" size="h5">
						Collaborative Governance
					</Heading>
					<p>
						Troon (including the on-site GM and staff) serves the membership at the direction of the ownership group. We
						can help you operate at a higher level, allowing you to get back to focusing on the strategic direction of
						your club and less on the day-to-day operation.
					</p>
				</section>

				<section class="flex flex-col gap-2">
					<Heading as="h3" size="h5">
						Member Value Adds
					</Heading>
					<p>
						At no extra cost to the club, the club is now part of a network of clubs to enjoy when traveling (190+ daily
						fee/resort courses and 145+ private courses).
					</p>
				</section>

				<section class="flex flex-col gap-2">
					<Heading as="h3" size="h5">
						Talent Acquisition
					</Heading>
					<p>
						Troon employs more industry professionals than any other company in club management, allowing us to utilize
						this talent pool to benefit our clients with the best talent at reasonable salaries.
					</p>
				</section>

				<section class="flex flex-col gap-2">
					<Heading as="h3" size="h5">
						Superior Resources to Enhance the Overall Experience
					</Heading>
					<p>
						With access to Troon’s talent and resource-rich operating platform, owners can now get action/execution on
						multiple fronts simultaneously.
					</p>
				</section>
				<section class="flex flex-col gap-2">
					<Heading as="h3" size="h5">
						Cost-Neutral Solution
					</Heading>
					<p>
						In most cases, the majority of our costs are covered annually through our efficiencies, buying power, and
						membership retention/growth.
					</p>
				</section>
			</NoHydration>

			<Video
				src={`${getConfigValue('CDN')}/web/video/management-services/private-clubs/index.m3u8`}
				poster={`${getConfigValue('IMAGE_HOST')}/web/video/management-services/private-clubs/poster.jpg`}
			/>
		</>
	);
}

export const route = {
	info: {
		nav: { hero: true },
		title: 'Private Clubs',
		description: 'Enhance your private club member experience.',
		hero: `${getConfigValue('IMAGE_HOST')}/web/hero/private.jpg`,
	},
} satisfies RouteDefinition;
